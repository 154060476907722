/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:da38afc1-2874-4353-89e0-695bba9e9dd5",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_WDlluMA9D",
    "aws_user_pools_web_client_id": "4tgsuee3aqv5n69tde163si37g",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "osi-s3uploader183157-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "s3uploaderui-20220723183508-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dh3styime4v14.cloudfront.net"
};


export default awsmobile;
